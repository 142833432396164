@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~swiper/swiper.min.css";
//@import "~slick-carousel/slick/slick.css";
//@import "~slick-carousel/slick/slick-theme.css";
//@import "~react-responsive-carousel/lib/styles/carousel.min.css";

html {
  background-color: #212529;
}

.App {
  text-align: center;
}

.primary-fade {
  background: linear-gradient(90deg, #dc2626, #f87171);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.grey-fade {
  background: linear-gradient(90deg, #828282, #b5b5b5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.primary-bg {
  background: linear-gradient(90deg, #dc2626, #f87171);
}
